<template>
    <div class="content">
        <BreadCrumbs
            :elements="[
                { link: 'Cart', name: 'Kosár' },
                { link: 'CheckoutBilling', name: 'Számlázási adatok' }
            ]"
        />
        <CartHeader />
        <section class="py-5">
            <div class="container">
                <div class="row">
                    <div class="col-md-6 offset-md-3">
                        <h1 class="title text-center">Számlázási adatok</h1>
                        <form v-on:submit.prevent="validateData">
                            <div class="my-4">
                                <label class="d-block">
                                    <input
                                        type="radio"
                                        name="usertype"
                                        :value="false"
                                        v-model="isCompany"
                                        checked
                                    />
                                    Magánszemélyként vásárolok
                                </label>
                                <label class="d-block">
                                    <input
                                        type="radio"
                                        name="usertype"
                                        :value="true"
                                        v-model="isCompany"
                                    />
                                    Cégként vásárolok
                                </label>
                            </div>
                            <div class="my-4">
                                <label>Név*</label>
                                <input
                                    type="text"
                                    name="name"
                                    v-model="model.shipping.name"
                                    class="form-control"
                                />
                            </div>
                            <div class="my-4" v-if="isCompany">
                                <label>Cégnév*</label>
                                <input
                                    type="text"
                                    name="company_name"
                                    v-model="model.billing.name"
                                    class="form-control"
                                />
                            </div>
                            <div class="my-4" v-if="isCompany">
                                <label>Adószám*</label>
                                <input
                                    type="text"
                                    name="vat_number"
                                    v-model="model.billing.vat_number"
                                    class="form-control"
                                />
                            </div>
                            <div class="my-4">
                                <label>Telefonszám*</label>
                                <input
                                    type="text"
                                    name="phone"
                                    v-model="model.shipping.phone"
                                    class="form-control"
                                />
                            </div>
                            <div class="my-4">
                                <label>E-mail*</label>
                                <input
                                    type="email"
                                    name="email"
                                    v-model="model.email"
                                    class="form-control"
                                />
                            </div>
                            <div class="title-small mb-4">
                                Számlázási adataim
                            </div>
                            <div class="my-4">
                                <label>Ország*</label>
                                <input
                                    type="text"
                                    name="country"
                                    v-model="model.billing.country"
                                    class="form-control"
                                />
                            </div>
                            <div class="my-4">
                                <label>Irányítószám*</label>
                                <input
                                    type="text"
                                    name="zip"
                                    v-model="model.billing.zip"
                                    class="form-control"
                                />
                            </div>
                            <div class="my-4">
                                <label>Város*</label>
                                <input
                                    type="text"
                                    name="city"
                                    v-model="model.billing.city"
                                    class="form-control"
                                />
                            </div>
                            <div class="my-4">
                                <label>Utca/Házszám*</label>
                                <input
                                    type="text"
                                    name="address"
                                    v-model="model.billing.address"
                                    class="form-control"
                                />
                            </div>
                            <div class="my-4">
                                <label class="d-block">
                                    <input
                                        type="checkbox"
                                        name="usertype"
                                        :value="1"
                                        v-model="isSameShipping"
                                        v-on:change="changeSameShip"
                                        checked
                                    />
                                    Szállítási címem megegyezik a számlázási
                                    címemmel.
                                </label>
                            </div>
                            <template v-if="!isSameShipping">
                                <div class="my-4">
                                    <label>Ország*</label>
                                    <input
                                        type="text"
                                        name="shipping_country"
                                        v-model="model.shipping.country"
                                        class="form-control"
                                    />
                                </div>
                                <div class="my-4">
                                    <label>Irányítószám*</label>
                                    <input
                                        type="text"
                                        name="shipping_zip"
                                        v-model="model.shipping.zip"
                                        class="form-control"
                                    />
                                </div>
                                <div class="my-4">
                                    <label>Város*</label>
                                    <input
                                        type="text"
                                        name="shipping_city"
                                        v-model="model.shipping.city"
                                        class="form-control"
                                    />
                                </div>
                                <div class="my-4">
                                    <label>Utca/Házszám*</label>
                                    <input
                                        type="text"
                                        name="shipping_address"
                                        v-model="model.shipping.address"
                                        class="form-control"
                                    />
                                </div>
                            </template>
                            <div class="row">
                                <div class="row">
                                    <div class="col-md-8">
                                        <router-link
                                            :to="'/'"
                                            class="text-grey d-block"
                                            >Vásárlás folytatása</router-link
                                        >
                                    </div>
                                    <div class="col-md-4 text-end">
                                        <button
                                            type="submit"
                                            class="btn btn-primary w-100 mb-2"
                                        >
                                            Tovább
                                        </button>
                                        <p class="text-mini text-grey">
                                            A *-al jelölt mezők kitöltése
                                            kötelező
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import BreadCrumbs from "@/components/BreadCrumbs";
import CartHeader from "@/components/CartHeader";

export default {
    name: "CheckoutBilling",
    components: { CartHeader, BreadCrumbs },
    data() {
        return {
            model: {
                name: null,
                email: null,
                billing: {
                    name: null,
                    country: null,
                    city: null,
                    zip: null,
                    address: null,
                    vat_number: null
                },
                shipping: {
                    name: null,
                    country: null,
                    city: null,
                    zip: null,
                    address: null,
                    phone: null
                }
            },
            isCompany: false,
            isSameShipping: true
        };
    },
    computed: {
        url: function() {
            return process.env.VUE_APP_API_URL;
        }
    },
    methods: {
        changeSameShip() {
            if (this.isSameShipping) {
                this.model.shipping.country = this.model.billing.country;
                this.model.shipping.city = this.model.billing.city;
                this.model.shipping.zip = this.model.billing.zip;
                this.model.shipping.address = this.model.billing.address;
            }
        },
        getUserData() {
            const self = this;
            return new Promise((resolve, reject) => {
                ApiService.get(`${self.url}/user/profile`)
                    .then(response => {
                    self.model = response.data.data;
                    if (
                        self.model.shipping.country === self.model.billing.country &&
                        self.model.shipping.zip === self.model.billing.zip &&
                        self.model.shipping.city === self.model.billing.city &&
                        self.model.shipping.address === self.model.billing.address
                    ) {
                        self.isSameShipping = true;
                    } else {
                        self.isSameShipping = false;
                    }

                    if (self.model.billing.vat_number) {
                        self.isCompany = true;
                    }

                    if (Object.keys(self.model.shipping).length < 1) {
                        self.model.shipping = {
                            name: null,
                            country: null,
                            city: null,
                            zip: null,
                            address: null,
                            phone: null,
                        };
                    }

                    if (Object.keys(self.model.billing).length < 1) {
                        self.model.billing = {
                            name: null,
                            country: null,
                            city: null,
                            zip: null,
                            address: null,
                            vat_number: null,
                        };
                    }
                    resolve(true);
                })
                .catch (error => {
                    console.error("Error fetching user data:", error);
                    reject(error)
                })
            })
        },
        validateData() {
            const self = this;
            let helper = JSON.parse(JSON.stringify(this.model));
            helper["company"] = this.isCompany;
            helper["same_shipping"] = this.isSameShipping;
            ApiService.post(`${this.url}/validate`, helper).then(() => {
                $cookies.set(`billing`, JSON.stringify(helper), 86400);
                self.$router.push({ name: "CheckoutShipping" });
            });
        }
    },
    created: async function () {
        if ($cookies.get("id_token") && $cookies.get("user")) {
            let user = await this.getUserData();
            if(
                user &&
                $cookies.get("billing") &&
                $cookies.get("billing").email == this.model.email
            ) {
                this.model = $cookies.get("billing")
                this.isSameShipping = this.model.same_shipping
                this.isCompany = this.model.company
            }
        } else {
            if ($cookies.get("billing")) {
                this.model = $cookies.get("billing");
                this.isSameShipping = this.model.same_shipping
                this.isCompany = this.model.company
            }
        }
    },
    watch: {
        $route: {
            handler: function() {
                /*if ($cookies.get("billing")) {
                    this.model = $cookies.get("billing");
                }*/
            },
            deep: true
        }
    }
};
</script>

<style scoped></style>
